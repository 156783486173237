body {
    background-image: url("assets/backimage.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
}

.Navbar {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(46, 44, 44, 0.703);
    z-index: 10;
}

.Navlinks {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 2rem;
    font-weight: 600;
}

.HamburgerMenu {
    top: 0;
    position: fixed;
    display: none;
}

.Navbar1 {
    display: flex;
    align-items: center;
    transition: height 0.5s;
}

.Navbar2 {
    background-color: white;
    display: none;
}

.Navbar2 a {
    color: black;
    text-decoration: none;
}

.Navbar2 .active {
    border: none;
    color: crimson;
}

.Navbar2 a:active {
    background-color: rgba(46, 44, 44, 0.703);
}


.Navlinks a {
    font-weight: 700;
    text-decoration: none;
    color: white;
    transition: transform 0.2s;

}

.Navlinks a::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: cyan;
    transition: width .3s;
}

.Navlinks a:hover::after {
    width: 100%;
}

a.active {
    color: aqua;
}

.ScrolltoTopButton {
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: 6%;
    position: fixed;
    top: 80%;
    left: 5%;
    z-index: 3;
    transition: opacity 0.5s;
    border-radius: 50%;
}

.HomeParentDiv {
    height: 100vh;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.HomeLeftSection {
    color: white;
}

.Myimage {
    width: 110%;
}

.AboutMe {
    height: 100%;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}


.Whatido h1 {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 700;
    margin-top: 1rem;
}

.AboutMePara {
    width: 60%;
    margin-top: 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AboutMePara p {
    margin-bottom: 0px;
}

.Skills {
    margin-top: 3rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

}

.MERN {
    padding: 1rem;
    background-color: rgba(235, 229, 229, 0.128);
    width: 60%;
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-bottom: 1rem;
}


.WebDesign {
    padding: 1rem;
    background-color: rgba(235, 229, 229, 0.128);
    width: 60%;
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-bottom: 1rem;
}

.LanguagesKnown {
    padding: 1rem;
    background-color: rgba(235, 229, 229, 0.128);
    width: 60%;
    display: flex;
    align-items: center;
    gap: 3rem;
}

.AboutusContent {
    display: flex;
    color: white;
    margin-right: auto;
    flex-direction: column;
}

.aboutContent {

    display: flex;
    flex-direction: column;
    align-items: center;

}

.PersonalInfo {
    margin-top: 4rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
}

.Name {
    width: 60%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
}

.Address {
    width: 60%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
}

.LocationSymbol {
    color: white;
}

.Education {
    width: 60%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
}

.font {
    color: white;
}

.Color {
    color: cyan;
}

.Color2 {
    color: white;
    margin-left: 6px;
}

.Pinfoheading {
    font-weight: 700;
    width: 60%;
}

.Projects {
    margin-top: 8rem;
}

.carousel-container {
    margin-top: 2rem;
    width: 50%;
}

.Images {
    width: 100%;
    margin: 0 auto;
}

.slick-dots li button:before {
    color: white !important;
    /* Change to your desired color */
}


.slick-prev {

    left: -4rem !important;
}

.slick-next {
    right: -4rem !important;
}

.slick-prev::before {
    font-size: 2rem !important;
}

.slick-next::before {
    font-size: 2rem !important;
}

.ImageParent {
    position: relative;
}

.layer {
    z-index: 3;
    position: absolute;
    background-image: linear-gradient(to bottom, rgba(52, 44, 44, 0.5), rgb(9, 9, 9));
    height: 0%;
    top: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 0.5s;
}

.layer p {
    opacity: 0;
    transition: opacity 0.5s;
}

.ImageParent:hover .layer {
    height: 100%;
}

.ImageParent:hover .layer p {
    opacity: 1;
}

.Button {
    border-radius: 5px;
    font-weight: 700;
    transition: transform 0.3s ease;

}

.Button:hover {
    border: 2px solid black;
    transform: scale(0.95);
}

.Experience {
    width: 100%;
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ExpHeading {
    width: 80%;
    display: flex;
}

.ExperienceDivs {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.ExperienceDivs1 {
    display: flex;
    justify-content: start;

}


.Resume {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    padding-bottom: 2rem;
}

.CertificateStyle {
    margin-top: 6rem;
    width: 50%;
    padding: 1rem;
}

.ListofExp {

    color: white;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.ContactHeading {
    width: 100%;
    text-align: center;
}

.Form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.FormParent {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

form input,
textarea {
    color: white;
    background-color: rgba(33, 32, 32, 0.561);
    border-radius: 5px;
}

.SocialIcons {
    width: 80%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    padding: 1rem;

}

.Social {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}

.SocialGmail {
    width: 25%;
    display: flex;
    justify-content: start;
    gap: 1rem;

}

.ContactME {
    height: 100%;
    margin-top: 7rem;
}

.CERTIFICATE {
    display: flex;
    justify-content: center;
    height: 100vh;

}

.IMCcertificateparent {
    margin-top: 6rem;
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
}

.IMCcertificate {
    width: 40%;
}

.IMCcertificateparent2 {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Assignment {
    margin-top: 7rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}


.custom-table1 th,
.custom-table1 td {
    border: 2px solid black;
    height: 4rem;
    text-align: center;
}

.custom-table1 th{
font-size: larger;

}
.custom-table1 {
    font-weight: 600;
}
.custom-table1 a {
    text-decoration: none;
    color: black;

}

.custom-table2 th,
.custom-table2 td {
    border: 2px solid black;
    text-align: center;
}
.custom-table2 {
    font-weight: 600;
    display: none;
}
.custom-table2 a {
    text-decoration: none;
    color: black;

}
