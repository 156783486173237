@media screen and (min-width:0px) and (max-width:480px) {
    .Navlinks {
        display: none;
    }

    .Myimage {
        width: 10rem;
    }

    .HomeParentDiv {
        width: 100%;
        padding: 1rem;
    }

    .HomeLeftSection {
        margin-top: 0rem;
        color: white;

    }

    .burgerspan {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .Navbar {
        justify-content: flex-start;
        height: 0;
    }

    .HamburgerMenu {
        background-color: rgba(46, 44, 44, 0.703);
        width: 100%;
        display: block;
        padding: 1rem;
    }

    .ImCross {
        margin-top: 1rem;
        margin-left: 0.5rem;
    }

    .Myname {
        display: none;
    }

    .Navbar2 {
        transition: left 0.5s;
        left: -15rem;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        width: 50%;
        z-index: 3;
        position: absolute;
        top: 0px;
        height: 100vh;
    }

    .Navbar2 span {
        width: 50%;
        padding-left: 4px;
    }

    .Navbar2 a {
        padding-left: 1rem;
    }


    .Navbar2 a {
        text-decoration: none;
        transition: transform 0.2s;
        font-weight: 700;
    }

    .Skills {
        margin-top: 2rem;
    }

    .MERN {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1rem;
    }

    .WebDesign {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1rem;
    }

    .LanguagesKnown {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1rem;
    }


    .AboutusContent {
        margin-right: 0px;
    }

    .AboutusContent h2 {
        text-align: center;

    }

    .AboutusContent p {
        text-align: center;

    }

    .LanguagesList {
        justify-content: center;
    }

    .aboutContent {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .AboutMePara {
        width: 100%;
        margin-top: 0rem;
    }

    .AboutMePara span {

        text-align: center;
    }

    .Pinfoheading {
        text-align: center;
        width: 100%;
    }

    .Name {
        width: 100%;
        padding: 1rem;
    }

    .Address {
        width: 100%;
        padding: 1rem;
    }

    .LocationSymbol {
        width: 30px;

    }

    .Education {
        width: 100%;
        padding: 1rem;
    }

    .font {
        font-size: 1.4rem;
    }

    .Projects {
        margin-top: 5rem;

    }

    .carousel-container {
        width: 80%;
    }

    .slick-prev {
        left: -24px !important;
    }

    .slick-next {
        right: -24px !important;
    }

    .slick-prev::before {
        font-size: 1rem !important;
    }

    .slick-next::before {
        font-size: 1rem !important;
    }

    .ExpHeading {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .ExperienceDivs {
        width: 100%;
    }

    .Experience {
        margin-top: 3rem;
    }

    .ListofExp {
        width: 100%;
    }

    .ExperienceDivs {
        padding-right: 1rem;
    }

    .IMCcertificate {
        width: 100%;
        height: 70%;
        margin-top: 4rem;
        margin-bottom: 2rem;

    }

    .CertificateStyle {
        width: 100%;
        height: 20rem;
    }

    .FormParent h5 {
        text-align: center;
    }

    form {
        padding: 0rem 1rem;
    }

    .SocialIcons {
        width: 100%;
    }

    .SocialIcons h5 {
        text-align: center;
    }

    .ContactME {
        margin-top: 5rem;
    }

    .Social {
        width: 100%;
    }

    .SocialGmail {
        width: 100%;
        justify-content: start;
    }

    .IMCcertificateparent {
        width: 100%;
        padding: 0 0.5rem;
        flex-direction: column;
    }

    .CERTIFICATE {
        height: 100%;
    }

    .custom-table1 {
        display: none;
    }

    .custom-table2 {
        display: block;
    }

    .Assignment {
        margin-top: 7rem;
        padding: 0 0;
        height: 90vh;
    }
}

@media screen and (min-width:400px) and (max-width:480px) {
    .custom-table1 {
        display: block;
    }

    .custom-table2 {
        display: none;
    }
}

@media screen and (min-width:480px) and (max-width:768px) {

    .HomeParentDiv {
        width: 100%;
    }

    .Myimage {
        width: 10rem;
    }


    .HomeLeftSection {
        margin-top: 0rem;
        color: white;
        padding-left: 1rem;
    }

    .ExperienceDivs {
        gap: 15%;
    }

    .ListofExp {
        width: 100%;

    }

    .ExperienceDivs {
        width: 100%;
    }

    .CertificateStyle {
        width: 28rem;
        height: 25rem;
    }


    .SocialIcons {
        width: 100%;
        align-items: start;
    }

    .Social {
        width: 100%;
    }

    .SocialGmail {
        width: 100%;
        justify-content: start;
    }

    .Myname {
        display: none;
    }

    .Navlinks {
        justify-content: space-between;
    }

    .Skills {
        margin-top: 2rem;
    }

    .MERN {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1rem;
    }

    .WebDesign {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1rem;
    }

    .LanguagesKnown {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1rem;
    }


    .AboutusContent {
        margin-right: 0px;
    }

    .AboutusContent h2 {
        text-align: center;
    }

    .AboutusContent p {
        text-align: center;
    }

    .LanguagesList {
        justify-content: center;
    }

    .aboutContent {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .AboutMePara {
        width: 100%;
        margin-top: 0rem;
    }

    .AboutMePara span {
        text-align: center;
    }

    .Pinfoheading {

        text-align: start;
        width: 100%;
    }

    .PersonalInfo {
        padding-left: 1rem;
    }

    .Name {
        width: 100%;
    }

    .Address {
        width: 100%;
    }

    .Education {
        width: 100%;
    }

    .ProjectImages {
        width: 100%;
    }

    form {
        padding: 0rem 1rem;
    }

    .carousel-container {
        margin-top: 3rem;
        width: 80%;
    }

    .slick-prev {
        left: -2rem !important;
    }

    .slick-next {
        right: -2rem !important;
    }

    .slick-prev::before {
        font-size: 1.5rem !important;
    }

    .slick-next::before {
        font-size: 1.5rem !important;
    }

    .IMCcertificateparent {
        width: 100%;
        gap: 1rem;
        padding: 0 0.5rem;
    }

    .IMCcertificate {
        width: 60%;
    }

    .custom-table2 {
        display: none;
    }

    .Assignment {
        height: 90vh;
    }


}

@media screen and (min-width:768px) and (max-width:1024px) {
    .HomeParentDiv {
        width: 90%;
    }

    .Myimage {
        width: 10rem;
        margin-right: 1rem;
    }

    .ExperienceDivs {
        gap: 11.5%;
    }

    .ExperienceDivs {
        width: 100%;
    }

    .SocialIcons {
        width: 100%;
        align-items: start;
    }

    .Social {
        width: 100%;
    }

    .SocialGmail {
        width: 100%;
        justify-content: start;
    }

    .ProjectImages {
        width: 100%;
    }

    .CertificateStyle {
        width: 40rem;
        height: 30rem;
    }

    .IMCcertificateparent {
        width: 100%;
        gap: 1rem;
    }

    .custom-table2 {
        display: none;
    }

    .Assignment {
        height: 90vh;
    }
}

@media screen and (min-width:1024px) and (max-width:1440px) {



    .Myimage {
        width: 10rem;
    }

    .HomeParentDiv {
        justify-content: flex-start;
        width: 100%;
        gap: 5%;
    }

    .HomeRightSection {
        padding-left: 2rem;
    }

    .ExperienceDivs {
        gap: 11.5%;
    }

    .ExperienceDivs {
        width: 100%;
    }

    .SocialIcons {
        width: 100%;
        align-items: start;
    }

    .Social {
        width: 100%;
    }

    .SocialGmail {
        width: 100%;
        justify-content: start;
    }

    .CertificateStyle {
        width: 45rem;
        height: 30rem;
    }

    .custom-table2 {
        display: none;
    }

    .Assignment {
        height: 90vh;
    }
}

@media screen and (min-width:1440px) and (max-width:1740px) {
    .Assignment {
        height: 90vh;
    }
}